<template>
  <div :class="RESULT_MODE != 5 ? 'reserve-form-wrap' : 'mo-reserve-wrap'">
    <!------------------------ 현장방문등록  -------------------------------->
    <!-- 예약입력  -->
    <div class="reserve-form-wrap--info" v-show="MODE == 1 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--top">
        <p class="reserve-form-wrap--title"><strong>휴대전화 번호</strong>를<br>입력해주세요.</p>
        <p class="reserve-form-wrap--text">
          견본주택 관람하실 고객님은<br/>
          <strong>휴대전화 번호로 방문등록</strong>을 하고<br/>
          안내에 따라 입장하여 주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          방문등록하고 <strong>카카오톡</strong>으로 확인하세요
        </div>
      </div>
    </div>

    <!-- 공통 전화번호 입력 -->
    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 1 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--keypad-in">
        <div class="reserve-form-wrap--keypad-top">
          <input type="text" readonly :value="CUST_PHONE">
        </div>
        <v-checkbox
          class="reserve-form-wrap--keypad-chk-label"
          :on-icon="'svg-chk-on'"
          :off-icon="'svg-chk-off'"
          v-model="IS_AGREE"
          label="서비스 이용약관과 개인정보 처리방식에 동의합니다."></v-checkbox>
      </div>
      <div class="reserve-form-wrap--keypad-pad">
        <div><button type="button" @click="fnClickNumber(1)" >1</button></div>
        <div><button type="button" @click="fnClickNumber(2)" >2</button></div>
        <div><button type="button" @click="fnClickNumber(3)" >3</button></div>
        <div><button type="button" @click="fnClickNumber(4)" >4</button></div>
        <div><button type="button" @click="fnClickNumber(5)" >5</button></div>
        <div><button type="button" @click="fnClickNumber(6)" >6</button></div>
        <div><button type="button" @click="fnClickNumber(7)" >7</button></div>
        <div><button type="button" @click="fnClickNumber(8)" >8</button></div>
        <div><button type="button" @click="fnClickNumber(9)" >9</button></div>
        <div><button type="button" class="btn-keypad-del" @click="fnDeleteNumber"><span class="hide">del</span></button></div>
        <div><button type="button" @click="fnClickNumber(0)" >0</button></div>
        <div><button type="button" class="btn-keypad-in" @click="fnNumberProc">입력</button></div>
      </div>
    </div>

    <!-- 약관 동의 -->
    <div class="reserve-form-wrap--terms" v-show="RESULT_MODE == 2">
      <p class="reserve-form-wrap--terms-txt" style="line-height: 1.5;">견본주택 입장을 위한<br><strong>방문등록을 해주세요.</strong></p>
      <p class="reserve-form-wrap--terms-subtxt">방문등록 및 간단한 설문진행 후 안내에 따라<br>입장하여 주시기 바랍니다.</p>
      <div class="reserve-form-wrap--terms-chk">
        <strong class="reserve-form-wrap--terms-chk--title">{{ CMP_NAME }}</strong>
        <div  class="reserve-form-wrap--terms-chk--all">
          <v-checkbox
            class="type-round"
            :on-icon="'svg-chk2-on'"
            :off-icon="'svg-chk2-off'"
            v-model="ALL_AGREE"
            @click="fnAgreeClick"
            label="모든 약관에 동의"></v-checkbox>
        </div>
        <div class="reserve-form-wrap--terms-chk--sub">
          <div class="d-flex">
            <v-checkbox
              class="type-round"
              :on-icon="'svg-chk2-on'"
              :off-icon="'svg-chk2-off'"
              v-model="PRSN_AGRE"
              @click="fnAgreeClick"
              label="개인 정보 수집및 이용동의"></v-checkbox>
            <button type="button" class="btn-default btn-link1" plain small>
              <v-icon small class="svg-arrow-next" @click="termView('prsn')"></v-icon>
            </button>
          </div>
          <div class="mt-8 d-flex">
            <v-checkbox
              class="type-round"
              :on-icon="'svg-chk2-on'"
              :off-icon="'svg-chk2-off'"
              v-model="MKTG_AGRE"
              @click="fnAgreeClick"
              label="마케팅 활용 동의"></v-checkbox>
            <button type="button" class="btn-default btn-link2" plain small>
              <v-icon small class="svg-arrow-next" @click="termView('mktg')"></v-icon>
            </button>
          </div>
        </div>

        <!-- 약관보기 팝업 -->
        <div>
          <v-dialog
            max-width="650"
            min-height="850"
            persistent
            v-if="dialogCAP0541"
            v-model="dialogCAP0541"
            content-class="square-modal min-auto"
          >
            <dialog-CAP0541
              headerTitle="약관관리"
              :param="CAP0541Param"
              @hide="hideDialog('CAP0541')"
            >
            </dialog-CAP0541>
          </v-dialog>
        </div>
      </div>
      <div class="reserve-form-wrap--terms-btn">
        <button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button>
        <button type="button" class="btn-keypad-in" @click="fnNextView">입력</button>
      </div>
    </div>

    <!-- 사전예약 안 한 고객 현장방문 설문 -->
    <!-- 테블릿 -->
    <div class="reserve-form-wrap--terms" v-show="RESULT_MODE == 3 && !MOBI_YN" style="overflow: auto; padding-bottom:300px !important; padding-top:70px !important;">
      <Survey 
        ref="Survey"
        :RESULT_MODE="this.RESULT_MODE" 
        :PAGE_MODE="'T'" 
        :PAGE_MODE2="'T'"
        :SURVEY_AF_DATAS="this.SURVEY_AF_DATAS" 
        @surveyEvent="fnSurveySaveAf"
        @surveyCmpDatas="fnSurveyCmpDatas"
      >
      </Survey>

      <div class="reserve-form-wrap--terms-btn" v-show="SURVEY_SHOW_BOOL">
        <button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button>
        <button type="button" class="btn-keypad-in" @click="fnBFSurveySave">입력</button>
      </div>
    </div>

    <!-- 모바일 -->
    <div class="mo-reserve-wrap" v-if="MOBI_YN" v-show="RESULT_MODE == 5 && MOBI_YN" style="padding:30px !important;">
      <Survey 
        ref="Survey"
        :RESULT_MODE="this.RESULT_MODE" 
        :PAGE_MODE="'M'" 
        :PAGE_MODE2="'T'"
        :SURVEY_AF_DATAS="this.SURVEY_AF_DATAS" 
        @surveyEvent="fnSurveySaveAf"
        @surveyCmpDatas="fnSurveyCmpDatas"
      >
      </Survey>

      <div class="reserve-form-wrap--terms" style="margin-top: 100px !important; position: inherit !important;">
        <div class="reserve-form-wrap--terms-btn" v-show="SURVEY_SHOW_BOOL">
          <button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button>
          <button type="button" class="btn-keypad-in" @click="fnBFSurveySave">입력</button>
        </div>
      </div>
    </div>

    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 4">
      <div class="reserve-form-wrap--keypad-in" style="text-align:center;line-height: 1.2;">
        {{ CUST_TXT }}<br/>
        <span style="font-size:50px;">방문등록 되었습니다.<br/>
          고객님의 방문을 환영합니다.<br/><br/></span>
        <span style="font-size:30px;">아래 확인 버튼을 누르고 방문 등록을 완료해 주세요.<br/>
          모바일로 다양한 분양정보를 받으실 수 있습니다.<br/>
          안내에 따라 입장하여 주시기 바랍니다.</span>
      </div>
      <div class="reserve-form-wrap--keypad-pad">
        <!-- <div><button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button></div> -->
        <div><button type="button" class="btn-keypad-in" @click="fnNumberEnd">확인</button></div>
      </div>
    </div>

    <!-- 공통 전화번호 입력 -->
    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 1 && MOBI_YN && T_BOOL">

      <div class="reserve-form-wrap--info" v-show="MODE == 1">
        <div class="reserve-form-wrap--sub">
          <div class="reserve-form-wrap--sub-kakao">
            방문등록하고 <strong>카카오톡</strong>으로 확인하세요
          </div>
        </div>
        <div class="reserve-form-wrap--top">
          <p class="reserve-form-wrap--text">
            견본주택 관람하실 고객님은<br/>
            <strong>휴대전화 번호로 방문등록</strong>을 하고<br/>
            안내에 따라 입장하여 주시기 바랍니다.
          </p>
        </div>
      </div>

      <div class="reserve-form-wrap--keypad-in">
        <div class="reserve-form-wrap--keypad-top">
          <input type="text" readonly :value="CUST_PHONE">
        </div>
        <v-checkbox
          class="reserve-form-wrap--keypad-chk-label"
          :on-icon="'svg-chk-on'"
          :off-icon="'svg-chk-off'"
          v-model="IS_AGREE"
          label="서비스 이용약관과 개인정보 처리방식에 동의합니다."></v-checkbox>
      </div>
      <div class="reserve-form-wrap--keypad-pad">
        <div><button type="button" @click="fnClickNumber(1)" >1</button></div>
        <div><button type="button" @click="fnClickNumber(2)" >2</button></div>
        <div><button type="button" @click="fnClickNumber(3)" >3</button></div>
        <div><button type="button" @click="fnClickNumber(4)" >4</button></div>
        <div><button type="button" @click="fnClickNumber(5)" >5</button></div>
        <div><button type="button" @click="fnClickNumber(6)" >6</button></div>
        <div><button type="button" @click="fnClickNumber(7)" >7</button></div>
        <div><button type="button" @click="fnClickNumber(8)" >8</button></div>
        <div><button type="button" @click="fnClickNumber(9)" >9</button></div>
        <div><button type="button" class="btn-keypad-del" @click="fnDeleteNumber"><span class="hide">del</span></button></div>
        <div><button type="button" @click="fnClickNumber(0)" >0</button></div>
        <div><button type="button" class="btn-keypad-in" @click="fnNumberProc">입력</button></div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import axios from "axios"
  import DialogCAP0541 from "./CAP0541.vue";
  import Survey from "@/components/Survey";
  import { mapState, mapGetters, mapActions } from "vuex";

  export default {
    name: 'MENU_CAP0540_co',
    components: {
      DialogCAP0541, // 모달 팝업
      Survey
    },
    metaInfo() {
      return {
        title: "미사전 예약자(방문확인)",
      }
    },
    props: {
    },
    data() {
      return {

        SURVEY_SHOW_BOOL:false,
        ACCESS_TOKEN: '',     //Arthur.Kim_알림톡 인증토큰

        CUST_TXT: '',
        MODE: 1, //예약입력 모드 {1: 기본, 2:대기상태}
        RESULT_MODE : 1,  //결과확인 모드 {1:입력, 2:처리결과}
        PAGE_MODE:'T',
        WAIT_NUM: 0, //대기팀
        CUST_PHONE : '010-', //키입력
        BAK_CUST_PHONE: '',
        CUST_NAME:'',
        USER_ID:'',
        IS_AGREE : true,
        ALL_AGREE : true,
        PRSN_AGRE : true,
        MKTG_AGRE : true,
        PRSN_AGRE_YN : 'Y',
        MKTG_AGRE_YN : 'Y',
        CMP_CNSL_ID : '',
        BUSS_CODE : this.$route.query.buss_code.replace(/[\"\']/gi, ''),
        CMP_ID : this.$route.query.cmp_id.replace(/[\"\']/gi, ''),
        CMP_NAME:this.$route.query.cmp_name.replace(/[\"\']/gi,''),
        PLUS_FRED_KEY:'',

        SURVEY_AF_DATAS: [],

        QUTN_ID:null,
        QUTN_NAME:'',
        QUTN_ITEM_CODES:[],
        QUTN_ITEM_NAMES:[],
        QUTN_ITEM_CONTS:[],
        QUTN_ITEM_ADDS:[],
        QUTN_ITEM_TYPE_CDS:['QUTN_ITEM_TYPE_CD_10'],  //['라디오박스타입코드']
        ORDER_SEQS:[],

        ageCds:[],
        areaCds:[],

        radioGroupSelected:[],
        SURVEY_LIST: [
          {
            Q: "귀하의 연령대를 선택하세요.",
            KEY: "age",
            A: [],
            required: true,
          },
          {
            Q: "현재 거주 지역을 선택하세요.",
            KEY: "resiArea",
            A: [],
            required: true,
          },
        ],

        // 약관 팝업
        dialogCAP0541: false,
        CAP0541Param : {}, // 약관 팝업 전달 값

        alertMsg:{
          error:'관리자에게 문의하세요.',
          confirmSave:'저장 하시겠습니까.'
        },

        ACCESS_TOKEN : "",    //Arthur.Kim_20220924 알림톡토큰
        EXPIRES_IN   : "",     //Arthur.Kim_20220924 알림톡토큰유효시간

        qutnItemList:[],
        chkQutnRply:[],
        selQutnRply:[],
        txtQutnRply:[],
        imgQutnRply:[],
        etcQutnRply:[],
        dateQutnRply:[],

        MOBI_YN: false,

        T_YN: '',
        T_BOOL: false, //테스트
      }
    },
    async mounted() {
      this.init();
      
      this.USER_ID=this.user_id;

      this.ACCESS_TOKEN = this.$store.getters['authStore/GE_ACCESS_TOKEN'];
      this.EXPIRES_IN = this.$store.getters['authStore/GE_EXPIRES_IN'];

      //모바일 여부
      this.MOBI_YN = this.mixin_mobi_yn();

      if(this.MOBI_YN) {
        this.PAGE_MODE = 'M';
        $('head').append('<meta name="viewport" content="width=device-width, initial-scale=0.55, maximum-scale=0.55, user-scalable=0">');
      }

      if(!this.mixin_isEmpty(this.$route.query.t)) {
        this.T_YN = this.$route.query.t.replace(/[\"\']/gi, '');
        if(this.T_YN == 'Y') {
          this.T_BOOL = true;
        }
      }
    },

    computed: {
      ...mapState({
        stateToken: "authStore/token",
      }),
      initHeaders(){
        return {
          SERVICE : 'hlw.campaign.manage.onSite.visit',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
      paramData(){
        return {
        };
      },
    },

    methods: {

      async init() {
      },

      fnSurveySaveAf(surveyAfSave) {
        if(!this.mixin_isEmpty(this.QUTN_ID)) {
          this.RESULT_MODE=4;
        }

        this.BAK_CUST_PHONE=this.CUST_PHONE;
        this.SURVEY_AF_DATAS = surveyAfSave;

        //사전예약 및 설문데이터 저장 테이블 key sett
        if (this.SURVEY_AF_DATAS.length > 0) {
          this.SURVEY_AF_DATAS.forEach((element, i) => {
            this.CMP_CNSL_ID = element.CMP_CNSL_ID;
            this.QUTN_ID = element.QUTN_ID;
            this.qutnItemList = element.QUTN_ITEM_LIST;
            this.chkQutnRply = element.CHK_QUTN_RPLY;
            this.selQutnRply = element.SEL_QUTN_RPLY;
            this.txtQutnRply = element.TXT_QUTN_RPLY;
            this.imgQutnRply = element.IMG_QUTN_RPLY;
            this.etcQutnRply = element.ETC_QUTN_RPLY;
            this.dateQutnRply = element.DATE_QUTN_RPLY;
          });
        }
        setTimeout(() => this.fnRegist(), 1000);

        this.fnMetaScale();
      },

      fnSurveyCmpDatas(datas) {
        this.BUSS_NAME=datas.BUSS_NAME;
        this.QUTN_ID=datas.QUTN_ID;

        if(this.mixin_isEmpty(datas.QUTN_ID)) {
          this.fnRegist();
          this.RESULT_MODE=4;
        }
      },

      async cmpBussDataFn() {
        let requestData = {
          headers: {
            SERVICE:this.initHeaders.SERVICE,
            METHOD:'data',
            TYPE:this.initHeaders.TYPE,
            URL:"/api/hlw/campaign/manage/onSite/visit/data",
          },
          sendData: {}
        };

        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["BUSS_CODE"]=this.BUSS_CODE;
        requestData.sendData["QUTN_ITEM_TYPE_CDS"]=this.QUTN_ITEM_TYPE_CDS.join();

        //api 호출 부분
        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        const retDatas=resData;
        for (let index = 0; index < retDatas.length; index++) {
          const element = retDatas[index];
          this.QUTN_ID=element.QUTN_ID.length===0?null:element.QUTN_ID;
          this.QUTN_NAME=element.QUTN_NAME;
          this.QUTN_ITEM_CODES.push(element.QUTN_ITEM_CODE);
          this.QUTN_ITEM_NAMES.push(element.QUTN_ITEM_NAME);
          this.QUTN_ITEM_CONTS.push(element.QUTN_ITEM_CONT);
          this.QUTN_ITEM_ADDS.push(element.QUTN_ITEM_ADD);
          this.QUTN_ITEM_TYPE_CDS.push(element.QUTN_ITEM_TYPE_CD);
          this.ORDER_SEQS.push(element.ORDER_SEQ);
        }
      },

      fnClickNumber(clickNumber) {
        if(this.mixin_isEmpty(this.CUST_PHONE)) {
          this.CUST_PHONE = clickNumber;
        }else{
          if(this.CUST_PHONE.length < 13) {
            this.CUST_PHONE = this.fnNumberFomat(this.CUST_PHONE + clickNumber);
          }
        }
      },

      fnDeleteNumber() {
        if(this.CUST_PHONE !== '010-') {
          if(!this.mixin_isEmpty(this.CUST_PHONE)) {
            var makePhoneNumber = this.CUST_PHONE.replaceAll('-', '');
            makePhoneNumber = makePhoneNumber.substring(0, makePhoneNumber.length-1);
            this.CUST_PHONE = this.fnNumberFomat(makePhoneNumber);
          }
        }
      },

      fnNumberProc() {
        if(!this.IS_AGREE) {
          this.common_alert("이용약관 동의후에 방문등록해 주세요.", "chk");
          return;
        }

        if(this.CUST_PHONE.length < 13) {
          this.common_alert("휴대전화번호를 정확히 입력해 주세요.", 'chk');
          return;
        }else{
          this.BAK_CUST_PHONE=this.CUST_PHONE;
          this.getListData();
        }
      },

      fnNumberFomat(phoneNumber) {
        var makePhoneNumber = "";
        if(!this.mixin_isEmpty(phoneNumber)) {
          makePhoneNumber = phoneNumber.replaceAll('-', '');
          if(makePhoneNumber.length > 9){
            makePhoneNumber = this.mixin_setPhoneNo(makePhoneNumber);
          }else{
            if(makePhoneNumber.length === 3){
              makePhoneNumber = makePhoneNumber + '-';
            }else if(makePhoneNumber.length === 7){
              makePhoneNumber = makePhoneNumber.substring(0,3) + '-' + makePhoneNumber.substring(3) + '-';
            }else if(makePhoneNumber.length > 7){
              makePhoneNumber = makePhoneNumber.substring(0,3) + '-' + makePhoneNumber.substring(3, 7) + '-' + makePhoneNumber.substring(7);
            }else if(makePhoneNumber.length > 3){
              makePhoneNumber = makePhoneNumber.substring(0,3) + '-' + makePhoneNumber.substring(3);
            }
          }
        }

        return makePhoneNumber;
      },

      fnBackView() {
        if(!this.MOBI_YN) {
          if(!this.mixin_isEmpty(this.QUTN_ID)||this.RESULT_MODE == 2) {
            this.RESULT_MODE = Number(this.RESULT_MODE)-1;
          }else{
            this.RESULT_MODE = 2;
          }
        }else{
          if(this.RESULT_MODE == 4) {
            this.RESULT_MODE = 5;
          }else if(this.RESULT_MODE == 5) {
            this.RESULT_MODE = 2;
          }else{
            this.RESULT_MODE = Number(this.RESULT_MODE)-1;
          }
        }

        this.fnSurveyDivLoad();
        this.fnMetaScale();
      },

      fnNextView() {
        if(this.MOBI_YN) {
          if(!this.mixin_isEmpty(this.QUTN_ID) && this.RESULT_MODE == 2) {
            this.RESULT_MODE = 5;
          }else{
            this.fnRegist();
            this.RESULT_MODE=4;
          }
        }else{
          if(!this.mixin_isEmpty(this.QUTN_ID) && this.RESULT_MODE == 2) {
            this.RESULT_MODE=Number(this.RESULT_MODE)+1;
          }else{
            this.fnRegist();
            this.RESULT_MODE=4;
          }
        }

        this.fnSurveyDivLoad();
        this.fnMetaScale();
      },

      fnSurveyDivLoad() {
        if(this.RESULT_MODE==3 || this.RESULT_MODE==5) {
          this.fnVisitSurvey();
          setTimeout(() => this.SURVEY_SHOW_BOOL=true, 333);
        }else{
          this.SURVEY_SHOW_BOOL=false;
        }
      },

      async getListData() {

        let custPh=this.CUST_PHONE;
        this.CUST_TXT = custPh.substring(0, custPh.length-4) + '****';
        
        let requestData = {
          headers: {},
          sendData: {},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/work/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.work.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CMP_ID"] = this.CMP_ID;              //캠페인 아이디
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              
        requestData.sendData["UPD_DT_YN"] = 'Y' //등록 여부 확인              
        requestData.sendData["CUST_PHONE"] = this.CUST_PHONE.replaceAll("-", "");       //핸드폰 번호

        let response = await this.common_postCall(requestData);

        if (response.HEADER.ERROR_FLAG) {
          this.RESULT_MODE = 1;

          if(!this.mixin_isEmpty(response.HEADER.MSG)) {
            this.common_alert(response.HEADER.MSG, "error");
          }else{
            this.common_alert("관리자에게 문의해주세요.", "error");
          }
          return;
        } else {
          let data = response.DATA;
          let surveyData = response.SURVEY_DATA;

          console.log(data)
          // 데이터가 존재하면 
          // if (!this.mixin_isEmpty(data)) {
            //공통코드 (VIST_GUB_CD) ->CNSL_STAT_CD_01(미방문),CNSL_STAT_CD_02(방문완료)
            //VIST_GUB_CD === 'CNSL_VISIT_CD_01' && CNSL_RSVT_DT
            //미방문이면서 사전예약 요청 날짜 시각이 있을 경우 사전예약자
            // if(data[0].VIST_GUB_CD==='CNSL_VISIT_CD_01' && !this.mixin_isEmpty(data[0].CNSL_RSVT_DT)){
            //   this.common_alert("사전예약 하신분은 현장방문 확인 바랍니다.", "chk");
            // }else if(data[0].VIST_GUB_CD==='CNSL_VISIT_CD_02'){
            //   this.common_alert("이미 방문 인증 되었습니다.", "chk");
            // }
            // else{
            //   this.common_alert("사전예약 데이터가 존재합니다.\n담당자에게 문의해주세요.", "chk");
            // }
            // return;
          // }else{

          // 데이터 없을 시 예외 처리
          if (!this.mixin_isEmpty(data)) {
            if(data[0].VIST_GUB_CD==='CNSL_VISIT_CD_01' && !this.mixin_isEmpty(data[0].CNSL_RSVT_DT)){
              return this.common_alert("사전예약 하신분은 현장방문 확인 바랍니다.", "chk");
            } else if(data[0].VIST_GUB_CD==='CNSL_VISIT_CD_02'){
              return this.common_alert("이미 방문 인증 되었습니다.", "chk");
            }
            this.RESULT_MODE = 2;

            if(!this.mixin_isEmpty(surveyData)) {
              surveyData.forEach(element => {
                this.QUTN_ID=element.CMP_QUTN_ID;
              });
            }

            this.fnMetaScale();
          } else {
            this.common_alert("현장방문 미등록 고객입니다.\n등록 후 상담예약을 진행해주세요.", "chk")
          }
        }
        // }
      },

      fnBFSurveySave() {
        //설문 함수 호출
        let requestData = {
          headers:{},
          sendData:{
            CUST_PHONE:this.BAK_CUST_PHONE
          }
        };
        this.$refs.Survey.fnSurveySaveBtn(requestData);
      },

      fnVisitSurvey() {
        //설문 함수 호출
        let datas = {
          CMP_ID:this.CMP_ID,
          QUTN_ID:this.QUTN_ID,
          ANS_BOOL:false, //답변 조회 여부
        };
        this.$refs.Survey.fnVisetSurveyCall(datas);
      },

      // 입력된 데이터 저장
      async fnRegist() {
        let requestData = {
          headers: {
            SERVICE:this.initHeaders.SERVICE,
            METHOD:'regist',
            TYPE:this.initHeaders.TYPE,
            URL:"/api/hlw/campaign/manage/onSite/visit/regist",
          },
          sendData: {}
        };

        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["BUSS_CODE"]=this.BUSS_CODE;
        requestData.sendData["CMP_CNSL_ID"]=this.CMP_CNSL_ID;
        requestData.sendData["QUTN_ID"]=this.QUTN_ID;
        
        requestData.sendData["CUST_PHONE"]=this.BAK_CUST_PHONE;
        requestData.sendData["CUST_USER_KEY"]=this.PLUS_FRED_KEY;

        requestData.sendData["TRGT_GUB_CD"]='CUST_TRGT_CD_02'; //현장등록
        requestData.sendData["VIST_GUB_CD"]='CNSL_VISIT_CD_02'; //현장방문 등록 완료
        requestData.sendData["IS_AGREE"]=this.IS_AGREE;
        requestData.sendData["PRSN_AGRE_YN"]=this.PRSN_AGRE_YN;
        requestData.sendData["MKTG_AGRE_YN"]=this.MKTG_AGRE_YN;
        requestData.sendData["USER_ID"]=this.USER_ID;

        requestData.sendData["QUTN_ITEM_LIST"]=this.qutnItemList;
        requestData.sendData["CHK_QUTN_RPLY"]=this.chkQutnRply;
        requestData.sendData["SEL_QUTN_RPLY"]=this.selQutnRply;
        requestData.sendData["TXT_QUTN_RPLY"]=this.txtQutnRply;
        requestData.sendData["IMG_QUTN_RPLY"]=this.imgQutnRply;
        requestData.sendData["ETC_QUTN_RPLY"]=this.etcQutnRply;
        requestData.sendData["DATE_QUTN_RPLY"]=this.dateQutnRply;

        //api 호출 부분
        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        if(resData.length>0) {
          resData=resData[0];
          this.CUST_TXT = resData.CUST_PHONE.substring(0, resData.CUST_PHONE.length-4) + '****';
        }

        //알림톡 발송
        this.sendMessage();
      },
      
      async sendMessage() {

        // this.mixin_alarmTalk_token();
        const sendCustPhone=this.CUST_PHONE;
    
        let requestData = {
          headers: {},
          sendData: {},
        };

        let token;
        
        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/alarmTalk-msgformat/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.alarmTalk-msgformat.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
        requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디
        requestData.sendData["CUST_PHONE"] = sendCustPhone; 
        requestData.sendData["MSG_TMPL_TYPE"] = "PLACE_NOTI";           //메세지 속성 코드(신청완료)    
          
        let response = await this.common_postCall(requestData);

        if (!response.HEADER.ERROR_FLAG) {
            
          let data = response.DATA;
          let data_btn = response.DATA_BTN;

          if (data != null && data.length > 0){
              
            let msgFormat =data[0].MSG_CONT;  //메세지내용
              
            msgFormat = msgFormat.replace(/#{사업지명}/g, data[0].BUSS_NAME).replace(/#{고객명}/g,"");
            // let sendMsgFormat = this.decode(msgFormat);
            
            let setButtons = [];
            let setquickButtons = [];
            
            let dataBtnLen = (typeof data_btn != undefined && typeof data_btn != "undefined") ? data_btn.length: 0;
            let i = 0;

            if(dataBtnLen > 0){
              for(; i < dataBtnLen; i++){
                if 	(data_btn[i].BTN_TYPE == "MSG_BTN_TYPE_CO"){    
                  //일반 버튼(button)
                  setButtons.push({
                    name: data_btn[i].BTN_NAME,
                    type: data_btn[i].BTN_LINK_TYPE,
                    url_mobile: data_btn[i].BTN_LINK_URL
                  });
                }else{
                  //바로가기 버튼(Quick_reply)
                  setquickButtons.push({
                    name: data_btn[i].BTN_NAME,
                    type: data_btn[i].BTN_LINK_TYPE,
                    url_mobile: data_btn[i].BTN_LINK_URL
                  });
                }
              }
            }
            const sendButton = { "button": setButtons };

            let requestAlarmTalkData = {
              headers: {},
              sendData: {},
            };

            let buttonToString = `'${JSON.stringify(sendButton)}'`;

            requestAlarmTalkData.headers["URL"] = "/api/hlw/campaign/alarmTalk-postmsg/manage/post";
            requestAlarmTalkData.headers["SERVICE"] = "hlw.campaign.alarmTalk-postmsg.manage";
            requestAlarmTalkData.headers["METHOD"] = "post";
            requestAlarmTalkData.headers["ASYNC"] = false;

            requestAlarmTalkData.sendData["CMP_ID"] = this.CMP_ID;
            requestAlarmTalkData.sendData["TRAN_SENDER_KEY"] = data[0].SNDR_KEY;
            requestAlarmTalkData.sendData["TRAN_TMPL_CD"] = data[0].MSG_TMPL_CODE;
            requestAlarmTalkData.sendData["TRAN_PHONE"] = this.CUST_PHONE.replaceAll("-", "");
            requestAlarmTalkData.sendData["TRAN_CALLBACK"] = data[0].SNDR_PHONE.replaceAll("-", "");
            requestAlarmTalkData.sendData["TRAN_MSG"] = msgFormat;
            requestAlarmTalkData.sendData["TRAN_REPLACE_MSG"] = msgFormat;
            if(sendButton["button"].length != 0) requestAlarmTalkData.sendData["TRAN_BUTTON"] = this.encode(buttonToString);

            let alarmTalkResponse = await this.common_postCall(requestAlarmTalkData);
            if (alarmTalkResponse.HEADER.ERROR_FLAG) {
              this.common_alert("관리자에게 문의해주세요.", "error");
            }
          } 
        }
      },

      // 약관보기
      async termView(term) {
        this.CAP0541Param = {
          bussCode: this.BUSS_CODE,
          termType: term,
        };
        this.showDialog('CAP0541');
      },

      // 모달 팝업 열기
      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      // 모달 팝업 닫기
      hideDialog(type) {
        this[`dialog${type}`] = false;
      },

      /**
      * XSS Encode
      */
      encode(strText) {

        if (strText === "" || strText == null) {
          return strText;
        }

        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        strText = strText.replaceAll("\r", "<br/>");
        strText = strText.replaceAll("\n", "<p>");
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");

        return strText;
      },

      decode(strText) {

        //값존재유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }

        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }

        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br/>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },

      fnNumberEnd() {
        this.RESULT_MODE = 1;
        this.IS_AGREE = true;
        this.ALL_AGREE = true,
        this.PRSN_AGRE = true,
        this.MKTG_AGRE = true,
        this.CUST_PHONE = '010-';
        this.fnMetaScale();
        this.$refs.Survey.fnRegistAf();
      },

      fnAgreeClick() {
        if (this.ALL_AGREE) {
          this.PRSN_AGRE = true;
          this.PRSN_AGRE_YN = 'Y';
          this.MKTG_AGRE = true;
          this.MKTG_AGRE_YN = 'Y';

        } else {
          this.PRSN_AGRE = false;
          this.PRSN_AGRE_YN = 'N';
          this.MKTG_AGRE = false;
          this.MKTG_AGRE_YN = 'N';
        }

        if (this.PRSN_AGRE) {
          this.PRSN_AGRE_YN = 'Y';
        } else {
          this.PRSN_AGRE_YN = 'N';
        }

        if (this.PRSN_AGRE) {
          this.MKTG_AGRE_YN = 'Y';
        } else {
          this.MKTG_AGRE_YN = 'N';
        }

        if (this.PRSN_AGRE && this.MKTG_AGRE) {
          this.ALL_AGREE = true;
        }
      },

      fnMetaScale() {
        if(this.MOBI_YN) {
          if(this.RESULT_MODE == 1) {
            $('head').append('<meta name="viewport" content="width=device-width, initial-scale=0.55, maximum-scale=0.55, user-scalable=0">');
          } 
  
          if(this.RESULT_MODE == 2) {
            $('head').append('<meta name="viewport" content="width=device-width, initial-scale=0.6, maximum-scale=0.6, user-scalable=0">');
          }
  
          if(this.RESULT_MODE == 3) {
            $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">');
          }
  
          if(this.RESULT_MODE == 4) {
            $('head').append('<meta name="viewport" content="width=device-width, initial-scale=0.6, maximum-scale=0.6, user-scalable=0">');
          } 

          if(this.RESULT_MODE == 5) {
            $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">');
          } 
        }
      },
    }
  }
</script>
  

<style scoped>
  .reserve-form-wrap {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
  }

  .btn-link1 {
    margin-left: 50px;
  }

  .btn-link2 {
    margin-left: 142px;
  }

  .svg-arrow-next {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6 4L13 5.4L8.4 10L13 14.6L11.6 16L5.6 10L11.6 4Z' fill='%23868894'/%3E%3C/svg%3E%0A");
    transform: rotate(180deg);
    margin-top: -14px;
  }

  .reserve-wrap--title {
    font-size: xx-large;
  }

  .reserve-wrap--survey-form---title {
    font-size: x-large;
  }

  .reserve-wrap--survey-form-sub {
    color: grey;
    font-size: large;
    padding: 5px 0 5px 0;
    margin: 5px 0 5px 0;
  }

  .reserve-wrap--survey-form-a {
    padding: 5px 0 15px 0;
  }

  .theme--light {
    padding-bottom: 15px !important;
  }

  .mdi-menu-down {
    padding-bottom: 0px !important;
  }

  .v-btn--rounded {
    padding-bottom: 0px !important;
  }

  .theme--light>.theme--light {
    padding-bottom: 0 !important;
  }

  .v-input__slot>.theme--light {
    padding-bottom: 0 !important;
  }

</style>
